import React from "react";
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import planet from "../assets/planetObject.png";

export const Contact = () => {
  const formInitialDetails = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  //   const handleSubmit = async (e) => {
  //     e.preventDefault();
  //     setButtonText("Sending...");
  //     let response = await fetch("http://localhost:5000/contact", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json;charset=utf-8",
  //       },
  //       body: JSON.stringify(formDetails),
  //     });

  //     setButtonText("Send");
  //     let result = await response.json();
  //     setFormDetails(formInitialDetails);
  //     if (result.code === 200) {
  //       setStatus({ succes: true, message: "Message sent successfully" });
  //     } else {
  //       setStatus({
  //         succes: false,
  //         message: "Something went wrong, please try again later.",
  //       });
  //     }
  //   };

  const [formDetails, setFormDetails] = useState(formInitialDetails);
  // eslint-disable-next-line
  const [buttonText, setButtonText] = useState("Send");
  // eslint-disable-next-line
  const [status, setStatus] = useState({});

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <img src={planet} alt="contact us"></img>
          </Col>
          <Col md={6}>
            <h2>Get In Touch</h2>
            <form>
              <Row>
                <Col sm={6} className={"px-1"}>
                  <input
                    type="text"
                    value={formDetails.firstName}
                    placeholder="First Name"
                    onChange={(e) => onFormUpdate("firstName", e.target.value)}
                  />
                </Col>
                <Col sm={6} className={"px-1"}>
                  <input
                    type="text"
                    value={formDetails.lasttName}
                    placeholder="Last Name"
                    onChange={(e) => onFormUpdate("lastName", e.target.value)}
                  />
                </Col>
                <Col sm={6} className={"px-1"}>
                  <input
                    type="email"
                    value={formDetails.email}
                    placeholder="Email Address"
                    onChange={(e) => onFormUpdate("email", e.target.value)}
                  />
                </Col>
                <Col sm={6} className={"px-1"}>
                  <input
                    type="tel"
                    value={formDetails.phone}
                    placeholder="Phone Number"
                    onChange={(e) => onFormUpdate("phone", e.target.value)}
                  />
                </Col>
                <Col className={"px-1"}>
                  <textarea
                    rows="6"
                    value={formDetails.message}
                    placeholder="Message"
                    onChange={(e) => onFormUpdate("message", e.target.value)}
                  ></textarea>
                  <button type="submit">
                    <span>{buttonText}</span>
                  </button>
                </Col>
                {status.message && (
                  <Col>
                    <p
                      className={
                        status.success === false ? "danger" : "success"
                      }
                    >
                      {status.message}
                    </p>
                  </Col>
                )}
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
